<template>
  <div class="mb-3 mt-5 pt-3">
    <div class="row">
      <div
        :class="{
          [$style.card__register]: true,
        }"
      >
        <div
          :class="{
            [$style.card__register__image]: true,
          }"
        >
          <img
            src="@/assets/images/Profile.png"
            width="131"
            height="125"
          >
        </div>

        <div class="d-flex justify-content-center align-items-center pbz-font subtitle-md-medium mt-5 mb-3" style="color: #1A1A1A !important">
          {{ $t('auth.userNotRegistered') }}
        </div>

        <div class="d-flex justify-content-center align-items-center pbz-font body-md-regular my-3" style="color: #999999 !important; text-align: center">
          {{ registerStatus }}
        </div>

        <a-button
          size="large"
          class="pbz-font body-md-bold text-center w-100 mt-3"
          :class="{
            [$style.button__submit]: true,
          }"
          @click.prevent="() => goLoginPage()"
        >
          <div v-if="!loading">
            {{ $t('auth.goToLogin') }}
          </div>
          <div v-if="loading">
            <a-icon type="loading" /> Loading
          </div>
        </a-button>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  computed: {
    registerStatus () {
      return "Mohon maaf user Anda belum terdaftar, Silahkan hubungi administrator Anda."
    },
    loading () {
      return this.$store.state.user.loading
    },
  },
  methods: {
    goLoginPage () {
      this.$store.dispatch('user/LOGOUT')
        .then(() => {
          this.$router.push({ path: '/auth/login' })
        })
        .catch((err) => {
          console.error(err)
        })
    },
  },
}
</script>

<style lang="scss" module>
@import "@/components/cleanui/system/Auth/style.module.scss";
</style>
